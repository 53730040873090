/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import head from 'lodash/fp/head.js';
import countBy from 'lodash/fp/countBy.js';
import intersection from 'lodash/fp/intersection.js';
import {
  DEFAULT_EDP,
  IS_DIRECTIONAL,
  REQUIRES_SERVICE_LOCATIONS,
  IS_CUSTOM,
  COMPONENT_ID, ARCHIVED,
} from '@hbrisk/sp3-risk-model-support/components/app/attributes/names/index.js';
import { required, emptyOr0to100k } from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import { YES_NO_VALUES } from '@hbrisk/sp3-risk-model-support/components/app/attributes/values/index.js';
import componentFormInputsAreValid
  from '@hbrisk/sp3-risk-model-support/components/form/validation/componentFormInputsAreValid.js';
import { getRange } from '@hbrisk/sp3-risk-model-support/models/locationSpecifier/index.js';
import { modelTypesById, MODEL_TYPE_2_ID } from '#constants/models/modelTypes/index.js';
import { buildingTypesById } from '#constants/buildingTypes/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import isEdpFloor from '#support/models/isFloor.js';
import makeNoDuplicateValuesAtFieldAcrossArrayItemsValidator
  from '#support/utility/form/validation/makeNoDuplicateValuesAtFieldAcrossItemsValidator.js';
import { edpsByKey } from '#constants/edps/index.js';

const componentPopulationItemPattern = /componentPopulation\[\d+]/;
const performanceGroupsPathPattern = /componentPopulation\[\d+]\.performanceGroups/;
const performanceGroupIndexCapturePattern = /performanceGroups\[(\d+)]/;

const componentFromPath = (values, state, path) => {
  const populationItemPath = head(path.match(componentPopulationItemPattern) || []);
  const componentUuid = get(`${populationItemPath}.componentUuid`, values);
  return selectComponentByUuid(state, { uuid: componentUuid });
};

const makeIfDirectionalValidator = (validator) => (
  value,
  values,
  state,
  path
) => {
  const component = componentFromPath(values, state, path);
  if (component && component[IS_DIRECTIONAL] === YES_NO_VALUES.YES) {
    return validator(value, values, state, path);
  }
  return undefined;
};

const makeIfNonDirectionalValidator = (validator) => (
  value,
  values,
  state,
  path
) => {
  const component = componentFromPath(values, state, path);
  if (component && component[IS_DIRECTIONAL] === YES_NO_VALUES.NO) {
    return validator(value, values, state, path);
  }
  return undefined;
};

export const requiredIfDirectional = makeIfDirectionalValidator(required);
export const requiredIfNonDirectional = makeIfNonDirectionalValidator(required);
export const emptyOr0to100kIfDir = makeIfDirectionalValidator(emptyOr0to100k);
export const emptyOr0to100kIfNonDir = makeIfNonDirectionalValidator(emptyOr0to100k);

export const requiredIfSupportsServiceLocations = (value, values, state, path) => {
  const component = componentFromPath(values, state, path);
  if (!component) {
    return undefined;
  }
  const { selectRepairTimeMethodSupportsServiceLocations } = modelTypesById[MODEL_TYPE_2_ID];
  const repairTimeMethodSupportsServiceLocations = selectRepairTimeMethodSupportsServiceLocations(
    state
  );
  return (
    component[REQUIRES_SERVICE_LOCATIONS] === YES_NO_VALUES.YES
    && repairTimeMethodSupportsServiceLocations
      ? required(value, values)
      : undefined
  );
};

export const noDuplicatePerformanceGroupNames = (
  makeNoDuplicateValuesAtFieldAcrossArrayItemsValidator(
    performanceGroupsPathPattern,
    performanceGroupIndexCapturePattern,
    'Duplicate name'
  )
);

const makeAtLeastOneItemValidator = (message) => (value) => (
  Array.isArray(value) && value.length > 0
    ? undefined
    : message
);

export const atLeastOneComponent = makeAtLeastOneItemValidator(
  'At least one component is required to populate your model'
);

export const atLeastOnePerformanceGroup = makeAtLeastOneItemValidator(
  'At least one performance group is required for each component'
);

export const atLeastOneLocation = makeAtLeastOneItemValidator(
  'At least one location is required for each performance group'
);

export const isValidStorySpecifier = (value, values) => {
  if (value === null || value === undefined) {
    return undefined;
  }
  const { error } = getRange(false, parseInt(values.numberOfStories, 10), value);
  return error === 'invalidRange' ? 'Invalid range' : undefined;
};

export const locationSpecifierHasValidRange = (
  value,
  values,
  state,
  path
) => {
  const component = componentFromPath(values, state, path);
  if (component) {
    const {
      [DEFAULT_EDP]: defaultEdpKey,
    } = component;
    if (!defaultEdpKey) {
      return undefined;
    }
    const { numberOfStories } = values;
    const { locationType } = edpsByKey[defaultEdpKey];
    const isFloor = isEdpFloor(locationType);
    const { error } = getRange(isFloor, parseInt(numberOfStories, 10), value);
    if (error === 'invalidRange') {
      return 'Invalid range';
    }
  }
  return undefined;
};

export const noOverlapInLocationSpecifierRanges = (
  value,
  values,
  state,
  path
) => {
  const messages = {
    singular: (isFloor) => `Duplicate ${isFloor ? 'floor' : 'story'}`,
    plural: (isFloor) => `Duplicate ${isFloor ? 'floors' : 'stories'}`,
  };
  const component = componentFromPath(values, state, path);
  if (!component) {
    return undefined;
  }
  const { [DEFAULT_EDP]: defaultEdpKey } = component;
  if (!defaultEdpKey) {
    return undefined;
  }
  const { numberOfStories } = values;
  const { locationType } = edpsByKey[defaultEdpKey];
  const isFloor = isEdpFloor(locationType);
  const items = value || [];
  const { ranges, combinedRanges } = items.reduce((acc, item) => {
    const { range } = getRange(
      isFloor,
      parseInt(numberOfStories, 10),
      item.locationSpecifier
    );
    return {
      ranges: [
        ...acc.ranges,
        range === undefined ? null : range,
      ],
      combinedRanges: range === undefined
        ? acc.combinedRanges
        : [
          ...acc.combinedRanges,
          ...range,
        ],
    };
  }, { ranges: [], combinedRanges: [] });

  const numberOfSpecificationsByLocationNumber = countBy((a) => a, combinedRanges);

  const locationNumbersWithMultipleSpecifications = Object.keys(
    numberOfSpecificationsByLocationNumber
  ).reduce((acc, locationNumber) => {
    const isError = numberOfSpecificationsByLocationNumber[locationNumber] > 1;
    return [
      ...acc,
      ...(isError ? [parseInt(locationNumber, 10)] : []),
    ];
  }, []);

  const errors = items.reduce((acc, item, i) => {
    const range = ranges[i];
    const result = intersection(range, locationNumbersWithMultipleSpecifications);
    const resultLength = result.length;
    const hasDuplicates = resultLength > 0;

    return hasDuplicates
      ? [
        ...acc,
        {
          locationSpecifier: resultLength > 1
            ? messages.singular(isFloor)
            : messages.plural(isFloor),
        },
      ]
      : [...acc, null];
  }, []);

  return errors.some((error) => (error !== null))
    ? errors
    : undefined;
};

export const componentIsValidIfCustom = (
  value,
  values,
  state,
  path
) => {
  const component = componentFromPath(values, state, path);
  if (component) {
    const {
      [COMPONENT_ID]: componentId,
      [IS_CUSTOM]: isCustom,
    } = component;
    if (isCustom) {
      if (!componentFormInputsAreValid(component, {}, { type: 'projected' })) {
        return `The definition of component ${componentId} is invalid. Fix the definition to use it in your models.`;
      }
    }
  }
  return undefined;
};

export const edpSupportedByModelStructuralSystems = (value, values, state, path) => {
  const component = componentFromPath(values, state, path);
  const { buildingTypeDir1, buildingTypeDir2 } = values;
  if (!component || !buildingTypeDir1 || !buildingTypeDir2) {
    return undefined;
  }
  const { [DEFAULT_EDP]: edpKey } = component;
  if (!edpKey) {
    return undefined;
  }
  const supportedDir1 = buildingTypesById[buildingTypeDir1].edps;
  const supportedDir2 = buildingTypesById[buildingTypeDir2].edps;
  return [...supportedDir1, ...supportedDir2].includes(edpKey)
    ? undefined
    : { _error: "The engineering demand parameter for this component is not supported by this model's structural system(s)" };
};

export const componentNotArchived = (value, values, state, path) => {
  const component = componentFromPath(values, state, path);
  if (!component) {
    return undefined;
  }
  const { [ARCHIVED]: archived } = component;
  return archived
    ? { _error: 'This component is archived. Unarchive the component to use it in your models' }
    : undefined;
};

export const zeroIfEdpNotSupportedByStructuralSystem = (structuralSystemField) => (
  value,
  values,
  state,
  path
) => {
  const component = componentFromPath(values, state, path);
  const { [structuralSystemField]: structuralSystem } = values;

  if (!component || !structuralSystem) {
    return undefined;
  }
  const { [DEFAULT_EDP]: edpKey } = component;
  if (edpKey) {
    return undefined;
  }
  const supported = buildingTypesById[structuralSystem].edps;
  return supported.includes(edpKey) || value === '0'
    ? undefined
    : 'Must be 0 given the engineering demand parameter for this component and the structural system for this direction';
};
